 <template>
  <div class="page">
    <!-- 搜索条件表单 -->
    <el-form class="ser_form" size="small" label-width="90px">
      <el-form-item class="el_form_item" label="司机手机">
        <el-input class="el_input" v-model="form.user_tel" clearable></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="司机姓名">
        <el-input
          class="el_input"
          v-model="form.user_name"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="发证机关">
        <el-input class="el_input" v-model="form.dl_ca" clearable></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="准驾车型">
        <el-select class="el_input" v-model="form.dl_class" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="A1" value="A1"></el-option>
          <el-option label="A2" value="A2"></el-option>
          <el-option label="B1" value="B1"></el-option>
          <el-option label="B2" value="B2"></el-option>
          <el-option label="C1" value="C1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="从业资格证">
        <el-select class="el_input" v-model="form.cy_status" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="未验证" value="1"></el-option>
          <el-option label="验证通过" value="2"></el-option>
          <el-option label="验证失败" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item" label="资格证号码">
        <el-input class="el_input" v-model="form.cy_code" clearable></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="审核状态">
        <el-select class="el_input" v-model="form.status" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="待审核" value="1"></el-option>
          <el-option label="审核通过" value="2"></el-option>
          <el-option label="审核驳回" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item elfrom_item" label="创建时间">
				<div class="block">
				<el-date-picker
					v-model="creat_time"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
      <el-form-item class="el_form_item" >
       
      </el-form-item>
      <el-form-item label-width="0">
        <el-button type="primary" @click="ser">查询</el-button>
        <el-button type="primary" @click="driver_export_open">导出</el-button>
        <!-- <el-button type="success" @click="open_add_view">添加</el-button> -->
        <el-button type="primary" @click="ser_para_clear">清空条件</el-button>
      </el-form-item>
    </el-form>
    <div class="total_info">
      <span>共:{{ list.total }}条</span>
    </div>
    <!-- 表格 -->
    <div class="tab_height">
      <el-table
        :data="list.data"
        :border="true"
        :stripe="true"
        size="small"
        v-loading="list.loading"
      >
        <el-table-column
          label="创建时间"
          prop="creat_time_text"
        ></el-table-column>
        <el-table-column label="司机信息">
          <template slot-scope="scope">
            <div>{{ scope.row.user_name }}</div>
            <div>{{ scope.row.user_tel }}</div>
          </template>
        </el-table-column>
        <el-table-column label="准驾车型" prop="dl_class"></el-table-column>
        <el-table-column
          label="从业资格证状态"
          prop="cy_status_text"
        ></el-table-column>
        <el-table-column
          label="上报状态"
          prop="upl_status_text"
        ></el-table-column>
        <el-table-column label="审核状态" prop="status_text">
           <template slot-scope="scope">
              <div>{{ scope.row.status_text }}</div>
              <div v-if="scope.row.status ==3">自动检查结果：{{ scope.row.ocr_check_info }}</div>
          </template>
          
        </el-table-column>

        <!-- 操作行 -->
        <el-table-column fixed="right" label="操作" width="48">
          <template slot-scope="scope">
            <el-button
              class="btn_left"
              v-if="scope.row.status == 2"
              @click="open_check_view(scope.row)"
              size="mini"
              type="text"
              >详情</el-button
            >
            <el-button
              class="btn_left"
              v-else
              @click="open_check_view(scope.row)"
              size="mini"
              type="text"
              >审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <el-pagination
      class="margin-top-xl"
      @size-change="page_size_change"
      @current-change="page_current_change"
      :current-page="page.p"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page.num"
      :total="list.total * 1"
      layout="total, sizes,prev, pager, next, jumper"
    >
    </el-pagination>

    <!-- 司机详情 -->
    <driverInfo
      :is_show="driver_info.is_show"
      :driver_info="driver_info.data"
      @close="check_view_close"
      @add ="open_check_view"
    ></driverInfo>
  </div>
</template>

<script>
import driverInfo from "./driver_info.vue";
import { Loading } from "element-ui";
export default {
  components: {
    driverInfo,
  },
  data() {
    return {
      //搜索条件
      form: {
        user_tel: "", //司机电话
        user_name: "", //司机姓名
        dl_ca: "", //行驶证发证机关
        dl_class: "", //准驾车型
        cy_status: "", //从业资格证状态
        cy_code: "", //从业资格证号码
        status: "", //状态(1:审核中,2:审核通过,3:审核未通过)
        creat_time_start:'',
        creat_time_end:'',
      },

      //表格数据
      list: {
        loading: false, //loading特效
        data: [], //列表数据
        total: 0, //列表总记录数
      },

      //页码信息
      page: {
        need_num: 1,
        num: 10,
        p: 1,
      },

      //审核弹出层
      driver_info: {
        data: {},
        is_show: 0,
      },
      driver_export_list:[],

			p:1,
      creat_time:'',//创建时间
      //快捷时间选择器
      pickerOptions: {
        shortcuts: [
        {
          text: "最近一周",
          onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          picker.$emit("pick", [start, end]);
          },
        },
        ],
      },
    };
  },
  created() {
    //获取页面数据
    this.get_page_data();
  },
  methods: {
    	//数据导出
			driver_export_open(){
					//读取数据
					this.$my.net.req({
						data:{
							mod:'truck_driver',
							ctr:'driver_list_by_admin',
							...this.form,
							p:this.p,
							num:1000,
						},
						callback:(data)=>{
							if(data.list.length>0){
								//预处理
								for(let item of data.list){
									//是否可用
									switch(item.status){
										case '1':item.status_text='审核中';break;
										case '2':item.status_text='审核通过';break;
										case '3':item.status_text='审核未通过';break;
									}
									item.name = item.user_name
									item.tel = item.user_tel
									item.id_card_num = item.id_card_num
									if(item.cy_img[0]){
										let key_arr=item.cy_img[0].split('__v__')
										item.cy_img1 = "http://img.jungong56.com/"+key_arr[0]
									}else{
										item.cy_img1 = ''
									}
									if(item.dl_img[0]){
										let key_arr=item.dl_img[0].split('__v__')
										item.dl_img1 = "http://img.jungong56.com/"+key_arr[0]
									}else{
										item.dl_img1 = ''
									}
									this.driver_export_list.push(item)
								}

								this.p++
								this.driver_export_open()
								Loading.service(this.options);
							}else{
								this.make_csv(this.driver_export_list)
								this.p=1
								this.driver_export_list = []
							}
							}
						})
					//未勾选数据
					// this.$my.other.confirm({
					// 	content:"当前未勾选数据,是否全部导出",
					// 	confirm:()=>{

					// 	}
					// });
			},
			
			//生成csv
			make_csv(data){
				var shunxu=[
					{key:"name",cname:"司机姓名"},
					{key:"tel",cname:"司机手机号"},
					{key:"id_card_num",cname:"身份证号码"},
					{key:"dl_img1",cname:"驾驶证"},
					{key:"cy_img1",cname:"从业资格证"},
					{key:"status_text",cname:"审核状态"},

				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<data.length;i++){
					for(var a in shunxu){
						str+=`${data[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "司机列表.xlsx";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				let loadingInstance = Loading.service(this.options);
					this.$nextTick(() => {
					loadingInstance.close();
				});
			},
    //清空查询条件
    ser_para_clear(){
     this.form= {
        user_tel: "", //司机电话
        user_name: "", //司机姓名
        dl_ca: "", //行驶证发证机关
        dl_class: "", //准驾车型
        cy_status: "", //从业资格证状态
        cy_code: "", //从业资格证号码
        status: "", //状态(1:审核中,2:审核通过,3:审核未通过)
        creat_time_end:'',
        creat_time_start:'',
      }
      this.creat_time = ''
      this.get_page_data();
    },
    //打开货车详情
    open_check_view(item,num) {
      //置入数据
      this.driver_info.data = item;

      //打开弹出层
      this.driver_info.is_show++;
    },
    check_view_close() {
      //上报界面关闭回调

      //复位
      this.driver_info.is_show = 0;

      //清空数据
      this.driver_info.data = {};

      //刷新页面
      this.get_page_data();
    },

    //搜索
    ser() {
      if (this.creat_time) {
        this.form.creat_time_start = parseInt(this.creat_time[0] / 1000);
        this.form.creat_time_end= parseInt(this.creat_time[1] / 1000);
      } else {
        this.form.creat_time_start = "";
        this.form.creat_time_end = "";
      }
      this.page.p = 1
      this.get_page_data();
    },

    //页宽变化
    page_size_change(num) {
      //置入页码
      this.page.num = num;

      //读取
      this.get_page_data();
    },

    //页码变化
    page_current_change(p) {
      //置入页码
      this.page.p = p;

      //读取
      this.get_page_data();
    },

    //读取页面数据
    get_page_data() {
      //加载中...
      // if(this.list.loading){
      // 	return;
      // }
      //置为加载中
      this.list.loading = true;
      console.log(this.form)
      //读取数据
      this.$my.net.req({
        data: {
          mod: "truck_driver",
          ctr: "driver_list_by_admin",
          ...this.form,
          ...this.page,
        },
        callback: (data) => {
          //加载完毕
          this.list.loading = false;

          //总数
          this.list.total = data.max;

          //预处理
          for (let item of data.list) {
            //创建时间
            item.creat_time_text = this.$my.other.totime(item.creat_time);

            //状态
            switch (item.status) {
              case "1":
                item.status_text = "审核中";
                break;
              case "2":
                item.status_text = "审核通过";
                break;
              case "3":
                item.status_text = "审核未通过";
                break;
            }

            //上报状态
            switch (item.upl_status) {
              case "1":
                item.upl_status_text = "未上报";
                break;
              case "2":
                item.upl_status_text = "上报成功";
                break;
              case "3":
                item.upl_status_text = "上报失败";
                break;
            }

            //准驾车型
            if (!item.dl_class) {
              item.dl_class = "未填写";
            }

            // //驾驶证
            // item.dl_img_src=this.$my.qiniu.make_src('driverdl',item.dl_img)

            // //从业资格证状态
            // item.cy_img_src=this.$my.qiniu.make_src('drivercy',item.cy_img)

            //从业资格证状态
            switch (item.cy_status) {
              case "1":
                item.cy_status_text = "未验证";
                break;
              case "2":
                item.cy_status_text = "验证通过";
                break;
              case "3":
                item.cy_status_text = "验证失败";
                break;
            }
          }

          //渲染
          this.list.data = data.list;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.in_check_img_item {
  width: 500px;
}

.in_tab_img_item {
  width: 100px;
}

//用户修改/添加表单的额外权限菜单部分
.menu_list {
  background-color: #eee;
  height: 500px;
  overflow: auto;
  padding: 0 20px;
  margin-top: 10px;
  .children_list {
    margin-left: 20px;
  }
}

//其他
.page {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  // height: calc(100% - 40px);
  overflow: auto;
}
.ser_form {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.tab_height {
  height: calc(100% - 134px);
}
.el_form_item {
  width: 275px;
}
.el_input {
  width: 94%;
}
.btn_left {
  margin: 0;
}
.total_info {
  text-align: right;
  font-size: 15px;
  margin-bottom: 10px;
  margin-right: 1px;
  color: #606266;
  span {
    margin-left: 10px;
  }
}
</style>