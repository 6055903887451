<template>
	<el-dialog title="导出搜索结果" :close-on-click-modal="false" width="300px" :visible.sync="is_show_in_page">
		<div style="text-align:center">总数量:{{max}} / 已读取:{{readed}}</div>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,//是否显示
			rull:Object,//搜索条件
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,
				
				//单次读取数量
				num:100,

				//总数量
				max:0,

				//已读取
				readed:0,

				//上次最后一个id
				id_max:false,

				p:1,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					this.max=0
					this.readed=0
					this.id_max=false
					this.buf = []
					this.read()
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {
			//生成csv
			make_csv(){
				var shunxu=[
					{key:"truck_tord_num",cname:"运单编号"},
					{key:"group_num",cname:"支付流水号"},
					{key:"out_trade_num",cname:"上游单号"},
					{key:"creat_time_text",cname:"支付时间"},

					{key:"case_addres",cname:"发货地址"},
					{key:"aim_addres",cname:"到货地址"},

					{key:"this_payed",cname:"支付金额"},
					{key:"pay_status_text",cname:"支付状态"},
					{key:"payed_time_text",cname:"支付时间"},
					{key:"extra_service_charge_rate",cname:"费率"},
					
					{key:"this_invoiced",cname:"发票金额"},
					{key:"invoice_status_text",cname:"开票状态"},
					{key:"this_settled",cname:"结算金额"},
					{key:"settle_status_text",cname:"结算状态"},
					{key:"settled_time_text",cname:"时间"},
                    {key:"extra_service_charge_total",cname:"服务费"},
					{key:"this_pay_type_text",cname:"支付类型"},
					{key:"shipper_company_name",cname:"货主公司名称"},
					{key:"truck_plate_num",cname:"车牌号"},
					{key:"driver_info_name",cname:"司机名称"},
					{key:"driver_info_tel",cname:"司机电话"},

					{key:"mark",cname:"备注"},
					
				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<this.buf.length;i++){
					for(var a in shunxu){
						str+=`${this.buf[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "支付记录.csv";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},

			//读取
			read(){
				var p = this.p
				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_payed_list_by_admin',
						need_num: 1,
						// is_get_shipper_info:true,
						// is_get_shipper_company_info:true,
						// is_get_truck_owner_info:true,
						// is_get_driver_info:true,
						// is_get_tord_info:true,
						...this.rull,
						num:this.num,
						p:p,
					},callback:(data)=>{
						this.p ++
						if(data.list.length ==0){
							this.p = 1
							this.make_csv()
							// this.read()
							return
						}
						//预处理
						for(let tord_item of data.list){
							//支付时间
							tord_item.creat_time_text=this.$my.other.totime(tord_item.creat_time);
							//各种信息置入
                            tord_item.case_addres = tord_item.tord_info.case_prov + tord_item.tord_info.case_city + tord_item.tord_info.case_county
                            tord_item.aim_addres = tord_item.tord_info.aim_prov + tord_item.tord_info.aim_city + tord_item.tord_info.aim_county
                            tord_item.payed_total_of_all = tord_item.this_payed
                            tord_item.shipper_company_name = tord_item.shipper_info.name
                            tord_item.driver_info_name = tord_item.driver_info.name
                            tord_item.driver_info_tel = tord_item.driver_info.tel
                            // tord_item.truck_info_name = tord_item.truck_owner_info.name
                            // tord_item.truck_info_tel = tord_item.truck_owner_info.tel
							tord_item.truck_plate_num = tord_item.tord_info.truck_plate_num
                            tord_item.this_settled=parseFloat(tord_item.this_settled_of_truck_owner)+parseFloat(tord_item.this_settled_of_driver)
							tord_item.payed_time_text=`${this.$my.other.totime(tord_item.payed_time)}`;
							if(tord_item.extra_service_charge_total==null){
								tord_item.extra_service_charge_total = '暂无服务费'
							}
							//支付完成时间
							if(tord_item.pay_status!=1){
								tord_item.payed_time_text=this.$my.other.totime(tord_item.payed_time);
							}else tord_item.payed_time_text="";
							//结算完成时间
							if(['3','4'].indexOf(tord_item.settle_status)!=-1){
								tord_item.settled_time_text=this.$my.other.totime(tord_item.settled_time);
							}else tord_item.settled_time_text="";
							switch(tord_item.pay_status){
								case '1':tord_item.pay_status_text="待审核";break;
								case '2':tord_item.pay_status_text="支付完成";break;
								case '3':tord_item.pay_status_text="支付失败";break;
							}
							switch(tord_item.this_pay_type){
								case '1':tord_item.this_pay_type_text="现金";break;
								case '2':tord_item.this_pay_type_text="燃油费";break;
								case '3':tord_item.this_pay_type_text="过路费";break;
							}
							if(!tord_item.this_pay_type_text){
								tord_item.this_pay_type_text = '无'
							}
							//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
							switch(tord_item.invoice_status){
								case '1':tord_item.invoice_status_text="未申请";break;
								case '2':tord_item.invoice_status_text="申请中";break;
								case '3':tord_item.invoice_status_text="已开出";break;
								case '4':tord_item.invoice_status_text="已驳回";break;
							}
							//结算状态(1:未申请,2:待打款,3:已打款,4:结算失败)
							switch(tord_item.settle_status){
								case '1':tord_item.settle_status_text="未申请";break;
								case '2':tord_item.settle_status_text="待打款";break;
								case '3':tord_item.settle_status_text="已打款";break;
								case '4':tord_item.settle_status_text="打款失败";break;
							}
					     }
                       //取出最后一个id
						if(data.list[data.list.length-1]){
                           this.id_max=data.list[data.list.length-1].id
						}

						//置入缓存
						this.buf=this.buf.concat(data.list)
						this.readed=this.buf.length
						
						//是否继续
						// if(this.readed<this.max){//没有达到最大数量则继续读取

						this.read();
						
						// }else{//达到最大数量则开始导出

						// 	this.make_csv()
						// }
                    }
				})
			},

			//启动
			start(){

				//读取总数量
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_payed_list_by_admin',
						// is_get_shipper_info:true,
						// is_get_shipper_company_info:true,
						// is_get_truck_owner_info:true,
						// is_get_driver_info:true,
						// is_get_tord_info:true,
						...this.rull,
                        num:10000,
					},
					callback:(data)=>{
						console.log(data,777)
						//如果总数量为0
						if(data.list.length==0){
							this.$my.other.msg({
								type:"warning",
								str:'该条件下数据为空'
							});
							return;
						
						//置入总数量
						}else this.max=data.list.length;

						//已读取数据
						this.buf=[];

						//开始读取
						this.read();
					}
				})
			},
		}
	}
</script>