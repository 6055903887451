 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="添加人手机">
				<el-input class="el_input" v-model="form.add_user_tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="添加人姓名">
				<el-input class="el_input" v-model="form.add_user_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车辆所有者">
				<el-input class="el_input" v-model="form.truck_owner_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="牌照类型">
				<el-select class="el_input" v-model="form.truck_plate_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="黄牌" value="1"></el-option>
					<el-option label="蓝牌" value="2"></el-option>
					<el-option label="绿牌" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="审核状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="待审核" value="1"></el-option>
					<el-option label="审核通过" value="2"></el-option>
					<el-option label="审核未通过" value="3"></el-option>
				</el-select>
			</el-form-item>
			 <el-form-item class="el_form_item elfrom_item" label="创建时间">
				<div class="block">
				<el-date-picker
					v-model="creat_time"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item" >
			
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="ser">查询</el-button>
				<!-- <el-button type="success" @click="open_add_view">添加</el-button> -->
			</el-form-item>
		</el-form>
		    <div class="total_info">
				<span>共:{{ list.total }}条</span>
			</div>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="创建时间" prop="creat_time_text"></el-table-column>
				<el-table-column label="添加人">
					<template slot-scope="scope">
						<div>添加: {{scope.row.add_user_name}} / {{scope.row.add_user_tel}}</div>
						<!-- <div>车主: {{scope.row.truck_owner_name}}</div> -->
					</template>
				</el-table-column>
				<el-table-column label="车辆信息">
					<template slot-scope="scope">
						<div>{{scope.row.truck_plate_num}} / {{scope.row.truck_plate_type_text}}</div>
						<div>{{scope.row.truck_type_name}} / {{scope.row.truck_type_name2}}</div>
					</template>
				</el-table-column>
				<el-table-column label="额载信息">
					<template slot-scope="scope">
						<div>额定载重:{{scope.row.truck_weight}}吨</div>
						<div>额载体积{{scope.row.truck_volume}}方</div>
					</template>
				</el-table-column>
				<el-table-column label="上报相关" width="220px">
					<template slot-scope="scope">
						<div style="display:flex;justify-content: space-between;width:100%">
							<div>上报状态:{{scope.row.upl_status_text}}</div>
							<div>无车代码:{{scope.row.logink_truck_code}}</div>
						</div>
						<div style="display:flex;justify-content: space-between;width:100%">
							<div>车辆总重:{{scope.row.truck_weight_max>0?scope.row.truck_weight_max+'吨':'未知'}}</div>
							<div>能源类型:{{scope.row.en_type_text}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="审核状态">
					<template slot-scope="scope">
						
						<div v-if="scope.row.status==3">打回原因:{{scope.row.check_faild_reason}}</div>
						<div>{{scope.row.status_text}}</div>
					</template>
				</el-table-column>
				<!-- 操作行 -->
				<el-table-column fixed="right" label="操作" width="48">
					<template slot-scope="scope">
						<el-button class="btn_left" @click="open_check_view(scope.row)" size="mini" type="text">审核</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination
			class="margin-top-xl"
			@size-change="page_size_change"
			@current-change="page_current_change"
			:current-page="page.p"
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num"
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper"
		>
		</el-pagination>

		<!-- 货车详情 -->
		<truckInfo
			:is_show="truck_info.is_show"
			:truck_info="truck_info.data"
			@close="check_view_close"
		></truckInfo>
	</div>
</template>

<script>
	import truckInfo from './truck_info.vue'
	export default {
		components:{
			truckInfo
		},
		data() {
			return {

				//搜索条件
				form: {
					add_user_tel:'',//添加人电话号码
					add_user_name:'',//添加人姓名
					truck_owner_name:'',//车主姓名(行驶证上的车辆所有者)
					status:'',//状态(1:待审核,2:审核通过,3:审核未通过)
					truck_plate_type:'',//牌照类型
					truck_plate_num:'',//车牌号
					creat_time_start:'',
					creat_time_end:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//审核弹出层
				truck_info:{
					data:{},
					is_show:0,
				},
				creat_time:'',//创建时间
				//快捷时间选择器
				pickerOptions: {
					shortcuts: [
					{
					text: "最近一周",
					onClick(picker) {
					const end = new Date();
					const start = new Date();
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
					picker.$emit("pick", [start, end]);
					},
					},
					{
					text: "最近一个月",
					onClick(picker) {
					const end = new Date();
					const start = new Date();
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
					picker.$emit("pick", [start, end]);
					},
					},
					{
					text: "最近三个月",
					onClick(picker) {
					const end = new Date();
					const start = new Date();
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
					picker.$emit("pick", [start, end]);
					},
					},
					],
				},
			}
		},
		created() {

			//获取页面数据
			this.get_page_data()
		},
		methods: {

			//打开货车详情
			open_check_view(item){

				//置入数据
				this.truck_info.data=item

				//打开弹出层
				this.truck_info.is_show++;
			},
			check_view_close(){//上报界面关闭回调

				//复位
				this.truck_info.is_show=0;

				//清空数据
				this.truck_info.data={}

				//刷新页面
				this.get_page_data()
			},

			//搜索
			ser(){
				if (this.creat_time) {
					this.form.creat_time_start = parseInt(this.creat_time[0] / 1000);
					this.form.creat_time_end= parseInt(this.creat_time[1] / 1000);
				} else {
					this.form.creat_time_start = "";
					this.form.creat_time_end = "";
				}
				this.page.p = 1
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){

				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_list_by_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//创建时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//是否可用
							switch(item.status){
								case '1':item.status_text='审核中';break;
								case '2':item.status_text='审核通过';break;
								case '3':item.status_text='审核未通过';break;
							}

							//上报状态
							switch(item.upl_status){
								case '1':item.upl_status_text='未上报';break;
								case '2':item.upl_status_text='已上报';break;
								case '3':item.upl_status_text='上报失败';break;
							}

							//车牌照类型
							switch(item.truck_plate_type){
								case '0':item.truck_plate_type_text='未知';break;
								case '1':item.truck_plate_type_text='黄牌';break;
								case '2':item.truck_plate_type_text='蓝牌';break;
								case '3':item.truck_plate_type_text='绿牌';break;
							}

							//能源类型
							switch(item.en_type){
								case 'A':item.en_type_text='汽油';break;
								case 'B':item.en_type_text='柴油';break;
								case 'C':item.en_type_text='电';break;
								case 'D':item.en_type_text='混合油';break;
								case 'E':item.en_type_text='天然气';break;
								case 'F':item.en_type_text='液化石油气';break;
								case 'L':item.en_type_text='甲醇';break;
								case 'M':item.en_type_text='乙醇';break;
								case 'N':item.en_type_text='太阳能';break;
								case 'O':item.en_type_text='混合动力';break;
								case 'Y':item.en_type_text='无';break;
								case 'Z':item.en_type_text='其他';break;
							}

							//货车类型
							if(!item.truck_type_name){
								item.truck_type_name="无"
							}

							//货车类型
							if(!item.truck_type_name2){
								item.truck_type_name2="无"
							}

							//无车代码
							let logink_truck_code=item.logink_truck_type.split(',')[1];
							item.logink_truck_code=logink_truck_code?logink_truck_code:"无"
						}
						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//弹出层
	.in_tab_img_item{
		width: 500px;
	}

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		// height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 100px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
	.total_info {
		text-align: right;
		font-size: 15px;
		margin-bottom: 10px;
		margin-right: 1px;
		color: #606266;
		span {
			margin-left: 10px;
		}
}
</style>